<template>
   <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="Fiscal Year"
          v-model.number="editedItem.fiscalYear"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="war-trans-fund"
          label="Fund"
          v-model="editedItem.fund"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="editedItem.description"
          label="Description"
        ></v-text-field>
      </v-col>
      <v-col cols="2" md="3">
        <v-checkbox
          v-model="editedItem.enableReconciliation"
          label="Enable Reconciliation"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GLFundEditedItem',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'GLFund-dialog',
    },
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'index',
    ]),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
};
</script>
