<template>
  <v-container>
    <div>
      <CRUDCriteria
        :showReset="true"
        :showSearch="false"
        >
        <template v-slot:criteriaRows>
          <v-row>
            <v-col cols="3">
              <v-select
                id="tmc-fund-criteria-fy"
                label="By FY"
                :items="fiscalYearRange"
                v-model.number="criteriaState.fiscalYear"
                @input="searchByCriteria"
              ></v-select>
            </v-col>
          </v-row>
        </template>
      </CRUDCriteria>
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :baseCrudKey="baseCrudKey"
        :headers="headers"
        :createNewItem="newItemDialog"
        :defaultSortBy="sortBy"
        toolbarTitle="GL Fund"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsertItem"
      >
        <template v-slot:[`editedItem`]="{}">
          <GLFundEditedItem
          :baseCrudKey="baseCrudKey"
          />
        </template>
      </CRUDList>
    </div>
   </v-container>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import CRUDList from '../../../common/base/crud/CRUDList.vue';
import CRUDCriteria from '../../../common/base/crud/CRUDCriteria.vue';
import GLFundEditedItem from './GLFundEditedItem.vue';
import {
  toFiscalYear,
  getFYList,
} from '../../../../util/shared/tmc-global';

const getHeaders = () => {
  const headers = [
    { text: 'Fund', value: 'fund' },
    { text: 'Description', value: 'description' },
  ];
  return headers;
};
export default {
  name: 'GLFundList',
  components: {
    CRUDCriteria,
    CRUDList,
    GLFundEditedItem,
  },
  data: () => ({
    baseCrudKey: 'GLFundList',
    headers: [],
    sortBy: [
      {
        column: 'fund',
        direction: 'asc',
      },
    ],
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.loadFiscalRegYears();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'index',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      fiscalYearRange: (state) => {
        const registerdYears = state.war.util.fundReconciliation.regYears || {};
        return getFYList(registerdYears);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapActions('war/util/glFund', [
      'loadGLFunds',
      'upsertGlFund',
    ]),
    ...mapActions('war/util/fundReconciliation', [
      'loadFiscalRegYears',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
      this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    },
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      this.loadGLFunds({
        criteria,
        baseCrudKey,
      });
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    upsertItem(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      if (!this.isNewItem()) {
        if (!window.confirm('This will affect to all associated Transactions, are you sure!')) {
          return false;
        }
      }
      this.upsertGlFund({ item: updatedItem, baseCrudKey });
      return true;
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        fiscalYear: toFiscalYear(new Date(this.todaysDate)),
        fund: null,
        description: null,
        enableReconciliation: false,
      };
      return newItem;
    },
  },
};
</script>
